import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Chat, Location, Message, Page, User} from "../../model/models";
import {Subscription} from "rxjs";
import {ChatServiceService} from "../../service/chat-service.service";
import {Timestamp} from "@angular/fire/firestore";
import {AuthService} from "../../service/auth.service";
import {Router} from "@angular/router";
import {LocationService} from "../../service/location.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {
  chats: Chat[] = [];
  messages: Message[] = [];
  selectedChatUser: any;
  currentLoggedUser!: User;
  newMessage: string = '';
  selectChatId: string = '';
  lastVisibleMessage?: Message;
  firstVisibleMessage?: Message;
  loadingMoreMessages: boolean = false;
  loadingNewMessages: boolean = false;
  hasMoreMessages: boolean = true;
  messagesPageSize: number = 100;
  selectedChatId: string = '';
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  @ViewChildren('messageElement') messageElements!: QueryList<ElementRef>;
  searchChatTerm: string = '';
  searchMessageTerm: string = '';
  foundChats: Chat[] = [];
  foundMessages: Message[] = [];

  locations: any[] = [];
  selectedLocationId: string = '';
  selectedLocation!: Location;
  locationId: string = '';

  page = new Page();
  sort: string = "createdDate,desc";
  searchText!: string;
  companyId?: string;

  private getMessageByChatSubscription: Subscription | undefined;
  private getMessagesByChatSubscription: Subscription | undefined;
  private getCustomerSubscription: Subscription | undefined;
  private getChatByLocationAndIdSubscription: Subscription | undefined;
  private getChatByLocationSubscription: Subscription | undefined;
  private getChatsByLocationSubscription: Subscription | undefined;
  private readonly token: any;

  constructor(private chatService: ChatServiceService,
              private authService: AuthService,
              private locationService: LocationService,
              private router: Router) {
    this.token = this.authService.loadToken();
    this.loadCurrentCompanyId();
    this.loadCurrentLoggedUser();
  }

  ngOnInit() {
    this.fetchLocationsTable();
    this.loadInitialChats().then(r => {});
  }

  async loadInitialChats() {
    this.chatService.getChatsByChatIdAcrossLocations(this.currentLoggedUser.id).subscribe(locationChats => {
      if (locationChats.length > 0) {
        if (!this.selectedChatId) {
          this.selectChat(locationChats[0].id ,locationChats[0].location.id);
        }
        this.selectedLocation ={
          locationId: locationChats[0].location.id,
          locationName: locationChats[0].location.name,
          chats : []
        }
        this.chats = locationChats;
      }
    });

  }

  selectChat(chatId: string , locationId : string): void {
    this.getMessageByChatSubscription?.unsubscribe();
    this.selectedChatId = chatId;
    this.selectedLocation = this.locations.find(location => location.locationId === locationId);
    this.selectedChatUser = {
      locationId: this.selectedLocation.locationId,
      locationName: this.selectedLocation.locationName,
      avatar: '../assets/icons/staff.svg'
    };
    this.getMessageByChatSubscription = this.chatService.getMessagesByChat(locationId, this.selectedChatId, this.currentLoggedUser, this.messagesPageSize).subscribe(messages => {
      if (messages.length > 0) {
        this.messages = messages;
        this.firstVisibleMessage = messages[0];
        this.lastVisibleMessage = messages[messages.length - 1];
        this.scrollToBottom();
        if (messages.length < this.messagesPageSize) {
          this.hasMoreMessages = false;
        }
      } else {
        this.initializeNewChat(chatId);
      }
    }, error => {
      this.initializeNewChat(chatId);
    });

  }

  initializeNewChat(chatId: string): void {
    this.messages = [];
    this.chats = [{
      id: chatId as any,
      location: {
        id: this.selectedLocationId,
        name: this.selectedLocation.locationName,
        avatar: '../assets/icons/staff.svg'
      },
      chatUser: {
        id: this.currentLoggedUser.id,
        name: this.currentLoggedUser.name,
        avatar: '../assets/icons/staff.svg'
      },
      latestMessage: null as any,
      createdAt: Timestamp.now(),
      unreadCount: 0
    }];
    this.selectedChatUser = {
      locationId: this.selectedLocation.locationId,
      locationName: this.selectedLocation.locationName,
      avatar: '../assets/icons/staff.svg'
    };
  }


  onLocationChange() {
    this.selectedLocation = this.locations.find(location => location.locationId === this.selectedLocationId);
    this.selectedLocation ={
      locationId: this.selectedLocationId,
      locationName: this.selectedLocation.locationName,
      chats : []
    }
    this.selectChat(this.currentLoggedUser.id ,this.selectedLocation.locationId);
  }

  onScroll(event: any) {
    const target = event.target;
    if (target.scrollTop === 0) {
      this.loadMoreMessages('up');
    }
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      this.loadMoreMessages('down');
    }
  }

  loadMoreMessages(direction: 'up' | 'down') {
    if (direction === 'up') {
      if (this.loadingMoreMessages || !this.hasMoreMessages) {
        return;
      }
      this.loadingMoreMessages = true;

      // Fetch older messages using endBefore for backward pagination
      this.getMessagesByChatSubscription = this.chatService.getMessagesByChat(
        this.selectedLocation.locationId,
        this.selectedChatId,
        this.currentLoggedUser,
        this.messagesPageSize,             // Limit size
        this.lastVisibleMessage, // Use lastVisibleMessage for downward pagination
        undefined        // No firstVisibleMessage
      ).subscribe(messages => {
        if (messages.length > 0) {
          // Add new messages to the top of the current list
          this.messages = [...messages, ...this.messages];
          this.firstVisibleMessage = messages[0]; // Update to the earliest message in the list

          if (messages.length < this.messagesPageSize) {
            this.hasMoreMessages = false;
          }
        } else {
          this.hasMoreMessages = false;
        }
        this.loadingMoreMessages = false;
      });
    } else if (direction === 'down') {
      if (this.loadingNewMessages) {
        return;
      }

      this.loadingNewMessages = true;

      // Fetch newer messages using startAfter for forward pagination
      this.getMessagesByChatSubscription = this.chatService.getMessagesByChat(
        this.selectedLocation.locationId,
        this.selectedChatId,
        this.currentLoggedUser,
        this.messagesPageSize,
        undefined,
        this.firstVisibleMessage,
      ).subscribe(messages => {
        if (messages.length > 0) {
          // Add new messages to the bottom of the current list
          this.messages = [...this.messages, ...messages];
          this.lastVisibleMessage = messages[messages.length - 1]; // Update to the latest message in the list
        }

        this.loadingNewMessages = false;
      });
    }
  }

  sendMessage() {
    this.chatService.sendMessage(this.selectedLocation.locationId, this.currentLoggedUser.id, this.newMessage, this.currentLoggedUser, this.selectedChatUser, this.selectedLocation)
      .then(() => {
        this.newMessage = '';
      })
      .catch((error) => {
      });
  }

  getChatsForLocation() {
    this.chatService.getChatsByLocation(this.locationId).subscribe(chats => {
      this.chats = chats;
    });
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
      }, 0);
    } catch (err) {
    }
  }

  async onChatSearch(): Promise<void> {
    if (this.searchChatTerm.trim()) {
      this.chats = await this.chatService.searchChatsByUserName(this.locationId, this.searchChatTerm);
      this.messages = [];
      if (this.chats.length > 0) {
        this.selectChat(this.chats[0].id , this.selectedLocation.locationId);
        this.locationId = this.selectedLocation.locationId;
      }
    }
  }

  async onMessageSearch(): Promise<void> {
    if (this.searchMessageTerm.trim()) {
      const foundMessages = await this.chatService.searchMessagesInChat(this.selectedChatId, this.locationId, this.searchMessageTerm);
      if (foundMessages.length > 0) {
        this.messages = foundMessages;
        this.scrollToMessage(this.messages[0].id);
      }
    }
  }

  scrollToMessage(messageId: string): void {
    const targetMessage = this.messageElements.find(
      (message) => message.nativeElement.id === messageId
    );

    if (targetMessage) {
      targetMessage.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      targetMessage.nativeElement.classList.add('highlighted'); // Add a highlight class
      setTimeout(() => {
        targetMessage.nativeElement.classList.remove('highlighted'); // Remove highlight after 2 seconds
      }, 2000);
    }
  }


  ngOnDestroy(): void {
    this.getMessageByChatSubscription?.unsubscribe();
    this.getMessagesByChatSubscription?.unsubscribe();
    this.getCustomerSubscription?.unsubscribe();
    this.getChatByLocationAndIdSubscription?.unsubscribe();
    this.getChatByLocationSubscription?.unsubscribe();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  fetchLocationsTable() {
    this.locationService.findAllLocationsByCompanyId(
      this.companyId,
      this.searchText,
      this.page.number,
      this.page.size,
      this.sort
    ).subscribe({
      next: (data: any) => {
        this.page = data?.page;
        this.page.totalPages = data?._embedded != undefined ? this.page.totalPages : 0;
        this.locations = this.getLocationDetails(data?._embedded?.locationSummaryDtoes)
      },
      error: () => {
        // this.isLoading = false;
      }
    });
  }

  loadCurrentCompanyId() {
    if (this.token) {
      this.companyId = this.token.companyId;
    }
  }

  getLocationDetails(array: any[]): any[] {
    return array.map(item => ({
      locationId: item.id,
      locationName: item.locationName
    }));
  }

  loadCurrentLoggedUser() {
    this.currentLoggedUser = {
      id: this.token.customerId,
      name: this.token.firstName +' '+  this.token.lastName,
      avatar: '../assets/icons/staff.svg'
    }
  }

  displayUnreadCount(chat: Chat): boolean {
    return chat.unreadCount !== 0 && chat.id == this.currentLoggedUser.id;
  }

}
