import {Timestamp} from "@angular/fire/firestore";


export interface Message {
  id: string;
  content: string;
  sender: User;
  receiver: User;
  timestamp: Timestamp;
  readStatus: boolean;
}

export interface LatestMessage {
  content: string;
  timestamp: Timestamp;
  sender: User;
}

export interface Chat {
  id: string;
  location: User;
  createdAt: Timestamp;
  latestMessage: LatestMessage;
  chatUser: User;
  unreadCount?: number;
}

export interface User {
  id: string;
  name: string;
  avatar: string;
}

export interface Location {
  locationId: string;
  locationName: string;
  chats: Chat[];
}

export enum LocationStatus {
  ACTIVE = "Active",
  COMING_SOON = "Coming soon",
  INACTIVE = "Inactive"
}

export interface LocationSummary {
  id: string;
  locationName: string;
  locationStatus: LocationStatus;
  monthlySales: number;
  occupancy: number;
  state: string;
  isRevenueGrowth: boolean;
}

export class Page {
  size: number = 0;
  totalElements: number = 0;
  totalPages: number = 0;
  number: number = 0;
}

export enum PaymentMethod {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  CREDIT_CARD = "CREDIT_CARD",
  CASH = "CASH",
  CREDIT_CARD_POS = "CREDIT_CARD_POS",
}

export interface Elementary {
  id?: string;
}

export interface Template extends Elementary {
  createdDate?: string;
  modifiedDate?: string;
  creator?: string;
  modifier?: string;
}

export interface Company extends Template {
  code: string;
  name: string;
  einNumber: string;
  dashboardUrl: string;
  email: string;
  website: string;
  phoneNumber: string;
  parentCompany: Company | null;
  subCompanies: Company[];
  feeStartDate?: string;
  enableAutoPay: boolean;
  companyType: | 'SUPER_ADMIN' | 'FRANCHISOR' | 'FRANCHISEE';
  primaryPaymentMethod: PaymentMethod;
  tax: number;
  colorCode: string;
}
