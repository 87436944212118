import {Injectable} from '@angular/core';
import {forkJoin, from, map, mergeMap, Observable} from "rxjs";
import {Chat, Location, Message, User} from "../model/models";
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where
} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class ChatServiceService {

  chatList: Chat[] = [];

  constructor(private firestore: Firestore) {
  }

  getAllChatsWithLatestMessage(): Observable<Chat[]> {
    return new Observable((observer) => {
      const chatRef = collection(this.firestore, 'chats');

      const unsubscribe = onSnapshot(chatRef, (snapshot) => {
        this.chatList = [];
        console.log(this.chatList);
        console.log(chatRef);
        snapshot.docChanges().forEach((change) => {
          let chat: any = change.doc.data();
          chat.id = change.doc.id;

          if (change.type === "added") {
            console.log("New chat: ", chat);
            this.chatList.push(chat);
          }
          if (change.type === "modified") {
            console.log("Modified chat: ", chat);
            const index = this.chatList.findIndex((x) => x.id === chat.id);
            this.chatList[index] = chat;
          }
          if (change.type === "removed") {
            console.log("Removed chat: ", chat);
            this.chatList = this.chatList.filter((x) => x.id !== chat.id);
          }
        });
        console.log(this.chatList);
        observer.next(this.chatList);
      });


      return () => unsubscribe();
    });

  }

  getDateAndTime() {
    const currDate = new Date();
    const dateStr: string = currDate.toDateString();
    console.log(dateStr);
    return dateStr;
  }

  async sendMessage(locationId: string, chatId: string | null, content: string, sender: User, receiver: User, location: Location): Promise<void> {
    try {
      const chatRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}`);
      const chatSnapshot = await getDoc(chatRef);
      if (!chatSnapshot.exists()) {
        const newChat: Partial<Chat> = {
          id: chatId as string,
          location: {
            id: location.locationId,
            name: location.locationName,
            avatar: '../assets/icons/staff.svg'
          },
          createdAt: Timestamp.now(),
          chatUser: {
            id: locationId,
            name: location.locationName,
            avatar: '../assets/icons/staff.svg'
          },
          latestMessage: {
            content: content,
            timestamp: Timestamp.now(),
            sender: sender
          }
        };
        await setDoc(chatRef, newChat);
      }
      const messagesRef = collection(chatRef, 'messages');
      const newMessage: Message = {
        id: '',
        content: content,
        sender: sender,
        receiver: receiver,
        timestamp: Timestamp.now(),
        readStatus: false
      };
      const messageDocRef = await addDoc(messagesRef, newMessage);
      const latestMessageUpdate: Partial<Chat> = {
        id: chatId as string,
        latestMessage: {
          content: newMessage.content,
          timestamp: newMessage.timestamp,
          sender: sender
        }
      };
      await updateDoc(chatRef, latestMessageUpdate);

      const messageIdUpdate: Partial<Message> = {
        id: messageDocRef.id
      };

      await updateDoc(messageDocRef, messageIdUpdate);
    } catch (error) {
      throw error;
    }
  }


  getMessagesByChat(
    locationId: string | undefined,
    chatId: string,
    currentLoggedUser: User,
    limitSize: number,
    lastVisibleMessage?: Message,
    firstVisibleMessage?: Message
  ): Observable<Message[]> {
    return new Observable((observer) => {
      console.log(locationId);
      console.log(`locations/${locationId}/chats/${chatId}/messages`);
      const messagesRef = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);
      let q;

      if (lastVisibleMessage) {
        q = query(
          messagesRef,
          orderBy('timestamp', 'asc'),
          startAfter(lastVisibleMessage.timestamp),
          limit(limitSize)
        );
      }

      else if (firstVisibleMessage) {
        q = query(
          messagesRef,
          orderBy('timestamp', 'asc'),
          endBefore(firstVisibleMessage.timestamp),
          limitToLast(limitSize)
        );
      } else {
        q = query(messagesRef, orderBy('timestamp', 'asc'), limit(limitSize));
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messagesList: Message[] = [];
        snapshot.forEach((doc) => {
          const message = doc.data() as Message;
          message.id = doc.id;

          if (!message.readStatus && message.sender.id !== currentLoggedUser.id) {
            this.markMessageAsRead(locationId, chatId, message.id).then(() => {
            });
          }

          messagesList.push(message);
        });
        console.log(messagesList);
        observer.next(messagesList);
      });

      return () => unsubscribe();
    });
  }


  getChatsByLocation(locationId: string): Observable<Chat[]> {
    return new Observable((observer) => {
      const chatsRef = collection(this.firestore, `locations/${locationId}/chats`);
      const q = query(chatsRef, orderBy('createdAt', 'desc'));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const chatList: Chat[] = [];

        const promises = snapshot.docs.map(async (doc) => {
          const chat = doc.data() as Chat;
          chat.id = doc.id;

          const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${doc.id}/messages`);
          const unreadQuery = query(unreadMessagesRef, where('readStatus', '==', false));
          const unreadSnapshot = await getDocs(unreadQuery);
          chat.unreadCount = unreadSnapshot.size;
          chatList.push(chat);
        });
        await Promise.all(promises);
        observer.next(chatList);
      });
      return () => unsubscribe();
    });
  }

  getChatByLocationAndId(locationId: string, chatId: string): Observable<Chat> {
    return new Observable((observer) => {
      const chatRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}`);
      const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);

      const unsubscribe = onSnapshot(chatRef, async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const chat = docSnapshot.data() as Chat;
          chat.id = docSnapshot.id;

          const unreadQuery = query(unreadMessagesRef, where('readStatus', '==', false),
            where('sender.id', '==', chatId));
          const unreadSnapshot = await getDocs(unreadQuery);

          chat.unreadCount = unreadSnapshot.size;
          observer.next(chat);
        } else {
          observer.next(undefined);
        }
      });
      return () => unsubscribe();
    });
  }


  async markMessageAsRead(locationId: string | undefined, chatId: string, messageId: string): Promise<void> {
    const messageRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}/messages/${messageId}`);

    try {
      await updateDoc(messageRef, {readStatus: true});
      console.log('Message marked as read:', messageId);
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  }

  getLocationsByChatId(chatId: string): Observable<Chat[]> {
    // console.log(chatId);
    /*   const fetchChats = async (): Promise<Chat[]> => {
         const locationsRef = collection(this.firestore, 'locations');
         const locationSnapshots = await getDocs(locationsRef);

         const allChats: Chat[] = [];

         // Loop through each location to search for the chatId in the 'chats' sub-collection
         const promises = locationSnapshots.docs.map(async (locationDoc) => {
           const locationId = locationDoc.id;
           console.log(locationId);
           /!*const chatsRef = collection(this.firestore, `locations/${locationId}/chats`);

           // Query to check if a chat with the specified chatId exists in this location
           const chatQuery = query(chatsRef, where('__name__', '==', chatId));
           const chatSnapshots = await getDocs(chatQuery);

           if (!chatSnapshots.empty) {
             const chatDoc = chatSnapshots.docs[0];
             const chat = chatDoc.data() as Chat;
             chat.id = chatDoc.id;
             // if (chat.location?.id) {
               // chat.location.id = locationId;
             // } // Attach locationId to the chat object
             console.log(chatDoc);
             // Fetch unread message count for the chat
             const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${chat.id}/messages`);
             const unreadQuery = query(unreadMessagesRef, where('readStatus', '==', false));
             const unreadSnapshot = await getDocs(unreadQuery);
             chat.unreadCount = unreadSnapshot.size;

             // Add chat to result list
             allChats.push(chat);*!/

           this.getChatsByLocation(locationId);
           // }
         });

         console.log(allChats);
         // Wait for all location queries to complete
         await Promise.all(promises);

         return allChats; // Return the list of chats with location IDs attached
       };

       // Use from() to convert the promise into an observable
       return from(fetchChats());*/

    /*    const fetchChats = async (): Promise<Chat[]> => {

          const locationsRef = collection(this.firestore, "locations");
          // const locationsQuery = query(locationsRef, limit(100));
          const locationSnapshots = await getDocs(locationsRef);

          console.log('Fetching locations...');

          try {

            if (locationSnapshots.empty) {
              console.warn('No documents found in locations collection.');
              // return []; // Return an empty array if no locations are found
            }

            console.log('Locations found:', locationSnapshots.size);

            const allChats: Chat[] = [];

            // Loop through each location to search for the chatId in the 'chats' sub-collection
            const promises = locationSnapshots.docs.map(async (locationDoc) => {
              const locationId = locationDoc.id;
              const chatsRef = collection(this.firestore, `locations/${locationId}/chats`);

              // Query to check if a chat with the specified chatId exists in this location
              const chatQuery = query(chatsRef, where('__name__', '==', chatId));
              const chatSnapshots = await getDocs(chatQuery);

              if (!chatSnapshots.empty) {
                const chatDoc = chatSnapshots.docs[0];
                const chat = chatDoc.data() as Chat;
                chat.id = chatDoc.id;
                // chat.locationId = locationId; // Attach locationId to the chat object

                // Fetch unread message count for the chat
                const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${chat.id}/messages`);
                const unreadQuery = query(unreadMessagesRef, where('readStatus', '==', false));
                const unreadSnapshot = await getDocs(unreadQuery);
                chat.unreadCount = unreadSnapshot.size;

                // Add chat to result list
                allChats.push(chat);
              }
            });
            // Wait for all location queries to complete
            await Promise.all(promises);

            console.log(allChats);
            return allChats; // Return the list of chats with location IDs attached

          } catch (error) {
            console.error('Error fetching locations:', error);
            throw error; // Re-throw the error for handling in the component
          }
        };

        // Use from() to convert the promise into an observable
        return from(fetchChats());*/


    const fetchChats = async (): Promise<string[]> => {
      const locationsRef = collection(this.firestore, 'locations');
      const locationSnapshots = await getDocs(locationsRef);
      return locationSnapshots.docs.map(doc => doc.id);
    };

    return from(fetchChats()).pipe(
      mergeMap((locationIds) => {
        // Create an array of observables, each calling getChatsByLocation for a location
        const chatObservables = locationIds.map(locationId =>
          this.getChatsByLocation(locationId).pipe(
            map(chats =>
              // Filter only chats that match the specific chatId
              chats.filter(chat => chat.id === chatId).map(chat => ({
                ...chat,
                locationId // Attach locationId to the chat
              }))
            )
          )
        );

        // Wait for all observables to complete and merge their results
        return forkJoin(chatObservables).pipe(
          map((chatsArray) => chatsArray.flat()) // Flatten the array of arrays
        );
      })
    );
  }

  getChatsAndMessagesByChatId(chatId: string): Observable<{ chat: Chat, messages: Message[] }[]> {
    const fetchChats = async (): Promise<string[]> => {
      const locationsRef = collection(this.firestore, 'locations');
      const locationSnapshots = await getDocs(locationsRef);

      // Return all location IDs
      return locationSnapshots.docs.map(doc => doc.id);
    };

    return from(fetchChats()).pipe(
      mergeMap((locationIds) => {
        // Create an array of observables, each calling getChatAndMessages for a location
        const chatObservables = locationIds.map(locationId =>
          this.getChatAndMessages(locationId, chatId)
        );

        // Wait for all observables to complete and merge their results
        return forkJoin(chatObservables).pipe(
          map((chatsArray) => chatsArray.filter(chatData => chatData !== null) as { chat: Chat, messages: Message[] }[])
        );
      })
    );
  }

  getChatsByChatIdAcrossLocations(chatId: string): Observable<Chat[]> {
    return new Observable((observer) => {
      const chatsQuery = query(
        collectionGroup(this.firestore, 'chats'),
        where('id', '==', chatId),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(chatsQuery, async (snapshot) => {
        const chatList: Chat[] = [];

        const promises = snapshot.docs.map(async (doc) => {
          const chat = doc.data() as Chat;
          chat.id = doc.id;

          const locationRef = doc.ref.parent.parent;
          if (locationRef) {
            const locationId = locationRef.id;

            const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${chat.id}/messages`);
            const unreadQuery = query(unreadMessagesRef,
              where('readStatus', '==', false),
              where('sender.id', '!=', chatId));
            const unreadSnapshot = await getDocs(unreadQuery);
            chat.unreadCount = unreadSnapshot.size;
          }
          chatList.push(chat);
        });

        await Promise.all(promises);

        observer.next(chatList);
      });

      return () => unsubscribe();
    });

  }

  getChatAndMessages(locationId: string, chatId: string): Observable<{ chat: Chat, messages: Message[] } | null> {
    return new Observable((observer) => {
      const chatDocRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}`);
      getDoc(chatDocRef).then((chatSnapshot) => {
        if (!chatSnapshot.exists()) {
          observer.next(null);
          observer.complete();
          return;
        }

        const chat = chatSnapshot.data() as Chat;
        chat.id = chatId;

        const messagesRef = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);
        getDocs(messagesRef).then((messagesSnapshot) => {
          const messages: Message[] = messagesSnapshot.docs.map(doc => {
            const message = doc.data() as Message;
            message.id = doc.id;
            return message;
          });

          observer.next({chat, messages});
          observer.complete();
        }).catch((error) => {
          console.error('Error fetching messages:', error);
          observer.error(error);
        });
      }).catch((error) => {
        console.error('Error fetching chat:', error);
        observer.error(error);
      });
    });
  }

  async searchMessages(searchTerm: string): Promise<Message[]> {
    const messagesCollection = collection(this.firestore, 'messages');
    const messagesQuery = query(messagesCollection, where('readStatus', '==', true));
    const querySnapshot = await getDocs(messagesQuery);
    const foundMessages: Message[] = [];

    querySnapshot.forEach((doc) => {
      foundMessages.push(doc.data() as Message);
    });
    return foundMessages;
  }

  async searchChatsByUserName(locationId: string, searchTerm: string): Promise<Chat[]> {
    const chatsCollection = collection(this.firestore, `locations/${locationId}/chats`);

    const chatsQuery = query(chatsCollection, where('chatUser.name', '>=', searchTerm),
      where('chatUser.name', '<=', searchTerm + '\uf8ff'));

    const querySnapshot = await getDocs(chatsQuery);
    const foundChats: Chat[] = [];

    querySnapshot.forEach((doc) => {
      const chatData = doc.data();
      const additionalProp = {unreadCount: 0};
      const combinedData = {...chatData, ...additionalProp};
      foundChats.push(combinedData as Chat);
    });

    return foundChats;
  }

  async searchMessagesInChat(chatId: string, locationId: string, searchTerm: string): Promise<Message[]> {
    const messagesCollection = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);
    const messagesQuery = query(messagesCollection, where('content', '>=', searchTerm),
      where('content', '<=', searchTerm + '\uf8ff'));

    const querySnapshot = await getDocs(messagesQuery);
    const foundMessages: Message[] = [];

    querySnapshot.forEach((doc) => {
      foundMessages.push(doc.data() as Message);
    });
    return foundMessages;
  }

}

